import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import axios from 'axios';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Slider from 'react-slick';
import drugPlaceholderImg from '../images/drugplaceholderImg.png';
import headerImage from '../images/headerimage.jpg'; 
import Modal from "../components/home/modal"


import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const query = graphql`
  query {
    allDrugs {
      edges {
        node {
          id
          atcCodeName
          dosageFormName
          productTradeName
          category
        }
      }
    }
  }
`;

const groupByCategory = (products) => {
  return products.reduce((acc, product) => {
    const { category } = product;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(product);
    return acc;
  }, {});
};

const ProductCatalogue = ({ data }) => {
  const isBrowser = typeof window !== "undefined"
  const products = data.allDrugs.edges.map(edge => edge.node);
  const groupedProducts = groupByCategory(products);
  const [cartCount, setCartCount] = useState(parseInt(isBrowser && window.localStorage.getItem('cartCount'), 10) || 0);

  const handleAddToCart = async (itemId) => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      alert('You need to be logged in to add items to the cart. Please log in.');
      navigate('/login');
      return;
    }

    try {
      const response = await axios.post(`${process.env.GATSBY_API_URL}/cart/add`, {
        userId,
        drugId: itemId,
        quantity: 1,
      });
      console.log('Item added to cart:', response.data);

      const itemExists = response.data.items.find(i => i.drug.toString() === itemId);

      if (itemExists) {
        localStorage.setItem('cartCount', response.data.items.length);
        setCartCount(response.data.items.length);
      } else {
        const currentCartCount = parseInt(localStorage.getItem('cartCount'), 10) || 0;
        localStorage.setItem('cartCount', currentCartCount + 1);
        setCartCount(currentCartCount + 1);
      }

      const updatedCart = await axios.get(`${process.env.GATSBY_API_URL}/cart/${userId}`);
      console.log('Updated Cart:', updatedCart.data);

      window.dispatchEvent(new Event('cartUpdated'));

    } catch (error) {
      console.error('Error adding item to cart:', error);
    }
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerMode: false,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          centerMode: false,
          centerPadding: "0",
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
          centerPadding: "0",
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: "0",
        }
      }
    ]
  };

  return (
    <Layout>
      <Modal />
      <SEO
        title="Patameds Catalogue - Explore Our Products"
        description="Discover our comprehensive catalogue of medical products at Patameds. Find detailed information on each product, including pricing and availability."
        keywords="medical products, product catalogue, healthcare, shopping, Patameds"
      />
            <HeaderImage src={headerImage} alt="Header Image" />
      <Container>
        <CategoryContainer>
          <CategoryScroll>
            {Object.keys(groupedProducts).map(category => (
              <CategoryButton key={category}>
                <Link to={`/category/${category}`}>
                  {category}
                </Link>
              </CategoryButton>
            ))}
          </CategoryScroll>
        </CategoryContainer>

        {/* Product catalogues */}
        {Object.keys(groupedProducts).map(category => (
          <div key={category}>
            <CategoryHeader>
              <CategoryTitle>{category}</CategoryTitle>
              <Link to={`/category/${category}`}>
                <MoreButton>More</MoreButton>
              </Link>
            </CategoryHeader>
            <StyledSlider {...sliderSettings}>
              {groupedProducts[category].map(product => (
                <ProductCardWrapper key={product.id}>
                  <ProductImage src={drugPlaceholderImg} alt={product.productTradeName} />
                  <ProductName>{product.productTradeName} - {product.atcCodeName}</ProductName>
                  <ProductName>{product.dosageFormName}</ProductName>
                  <ProductActions>
                    {/* <button onClick={() => handleAddToCart(product.id)}>Add to Cart</button> */}
                    <Link to={`/product/${product.productTradeName.replace(/ /g, "+").toLowerCase()}`} className='mb-3'>
                      <button>Buy</button>
                    </Link>
                    <Link to={`/product/${product.productTradeName.replace(/ /g, "+").toLowerCase()}`}>
                      <button>Details</button>
                    </Link>
                  </ProductActions>
                </ProductCardWrapper>
              ))}
            </StyledSlider>
          </div>
        ))}
      </Container>
    </Layout>
  );
};

export default ProductCatalogue;

const HeaderImage = styled.img`
  width: 800px; 
  height: 300px; 
  object-fit: cover; 
  display: block;
  margin: 0 auto; 
`;

const Container = styled.div`
  max-width: 1200px; 
  margin: 0 auto;
  padding: 0 20px;
  gap: 20px;
  overflow-x: hidden; 

  @media (max-width: 1024px) {
    max-width: 960px; 
  }

  @media (max-width: 768px) {
    max-width: 90%; 
    padding: 0 20px; 
  }
`;


const CategoryContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const CategoryScroll = styled.div`
  display: inline-flex;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%; 
  padding: 10px 0; 

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #03989e; 
    border-radius: 10px; 
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #027a78; 
  }

  &::-webkit-scrollbar-track {
    background-color: #f0f0f0; 
    border-radius: 10px; 
  }
`;

const CategoryButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 10px;
  text-align: center;
  margin-right: 40px; 

  a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
  }
`;

const CategoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const CategoryTitle = styled.h2`
  margin-right: 10px; 
  font-size: 24px; 
  font-weight: bold; 
  color: #333; 
  text-transform: capitalize; 
  margin-bottom: 10px; 
  text-align: center; 
`;

const MoreButton = styled.button`
  margin-left: 10px;
  padding: 8px 16px; 
  background-color: #4db6ac; 
  color: #ffffff; 
  border: none; 
  border-radius: 24px; 
  cursor: pointer; 
  transition: background-color 0.3s ease; 
  
  &:hover {
    background-color: #009688; 
  }

  &:focus {
    outline: none; 
  }
`;



const ProductCardWrapper = styled.div`
  width: 338.98px;
  height: 429.80px;
  position: relative;
  background-color: #ffffff;
  border-radius: 24px;
  border: 1px solid #dddddd;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 20px;
`;

const ProductImage = styled.img`
  width: 318.98px;
  height: 212.59px;
  object-fit: contain;
  border-radius: 12px;
`;

const ProductActions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;

  button {
    padding: 8px 16px;
    background-color: #03989e;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 24px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: #037d7f;
    }
  }

  a {
    text-decoration: none;
  }
`;

const ProductName = styled.h3`
  font-size: 20px;
  color: #333;
  margin: 10px 0;
  text-align: center;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  max-width: 100%;
`;



const StyledSlider = styled(Slider)`
  .slick-prev,
  .slick-next {
    width: 30px; 
    height: 30px; 
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  .slick-prev {
    left: -60px; 
  }

  .slick-next {
    right: -60px;
  }

  .slick-dots {
    bottom: 10px;
  }

  .slick-slide {
    padding: 0 5px; 
  }

  @media (max-width: 1024px) {
    .slick-prev,
    .slick-next {
      width: 25px;
      height: 25px; 
    }

    .slick-prev {
      left: -60px;
    }

    .slick-next {
      right: -60px;
    }
  }

  @media (max-width: 768px) {
    .slick-prev,
    .slick-next {
      width: 20px; 
      height: 20px; 
    }

    .slick-prev {
      left: -60px;
    }

    .slick-next {
      right: -60px; 
    }
  }
`;

